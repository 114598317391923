@import url('https://fonts.googleapis.com/css?family=Roboto:400,700&subset=latin-ext');
@import url('https://fonts.googleapis.com/css?family=Lobster:300,400&display=swap&subset=latin-ext');

div, header, article, section, footer, nav {
  position: relative;
}


html, body {
  padding: 0; margin: 0;
  width: 100%;
}

html {
  height: 100%;
  font-family: 'Roboto', sans-serif;
  font-size: 15px;
  font-weight:300;

}

body {
  display: flex;
	flex-direction: column;
	min-height: 100%;
}

#root {
  display: flex;
  flex-grow: 1;
  flex-direction: column;
}




